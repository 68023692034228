import clsx from "clsx";
import { HTMLAttributes, PropsWithChildren } from "react";

// NOTE: Talk with design team to keep naming in sync
// We should be able to use the same named headlines in Figma
// These will scale to match design system based on breakpoints - See SIZE_MAP
export type HeadlineSizes = "xxs" | "xs" | "sm" | "base" | "md" | "lg" | "xl";

type HealdineProps = {
  as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  size: HeadlineSizes;
  textTransform?: string;
  fontWeight?: string;
  fluid?: boolean;
  // NOTE: Always set the size of a headline via the size prop using {HeadlineSizes} -- !!! DO NOT set the size via tailwind className prop !!!
} & HTMLAttributes<HTMLHeadingElement>;

// TBD - figure out the various text sizes at each breakpoint, I'm just making assumptions here
export const HEADLINE_SIZE_MAP = {
  xxs: "text-base tablet:text-md laptop:text-lg",
  xs: "text-md tablet:text-lg laptop:text-xl",
  sm: "text-lg tablet:text-xl laptop:text-3xl",
  base: "text-xl tablet:text-3xl laptop:text-6xl",
  md: "text-3xl tablet:text-6xl laptop:text-8xl",
  lg: "text-6xl tablet:text-8xl laptop:text-9xl",
  xl: "text-8xl tablet:text-9xl laptop:text-10xl",
};

export const FLUID_HEADLINE_SIZE_MAP = {
  xxs: "text-fluid-headline-xxs",
  xs: "text-fluid-headline-xs",
  sm: "text-fluid-headline-sm",
  base: "text-fluid-headline-base",
  md: "text-fluid-headline-md",
  lg: "text-fluid-headline-lg",
  xl: "text-fluid-headline-xl",
};

function getHeadlineClass(size: HeadlineSizes, fluid: boolean) {
  return fluid ? FLUID_HEADLINE_SIZE_MAP[size] : HEADLINE_SIZE_MAP[size];
}

export function Headline({
  as: Heading = "h1",
  textTransform = "uppercase",
  fontWeight = "font-medium",
  fluid = false,
  size,
  children,
  className,
  ...props
}: PropsWithChildren<HealdineProps>) {
  return (
    <Heading
      className={clsx(
        "leading-none", // NOTE: these should only be styles shared by all headlines.
        fontWeight,
        textTransform, // NOTE: most headlines are uppercase but this allows us to override that if needed
        getHeadlineClass(size, fluid),
        className,
      )}
      {...props}
    >
      {children}
    </Heading>
  );
}
