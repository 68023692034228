import Location from "@/src/routes/location";
import useAddTheme from "@/libs/hooks/useAddTheme";
import NextPageNoProvidersLayout from "@/src/components/Layout/NextPageNoProvidersLayout";
import NextPageWithLayout from "@/src/components/Layout/NextPageWithLayout";
import { NextPageNoProviderLayoutProps } from "@/src/types/next";

const LocationNotSupported: NextPageNoProviderLayoutProps = () => {
  useAddTheme("theme-onboarding");
  return <Location />;
};

export default NextPageWithLayout({
  Page: LocationNotSupported,
  Layout: NextPageNoProvidersLayout,
});
